

import React, { Component, lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { ProtectedRoute } from "./ProtectedRoute";
import { CouponRoutes } from './routes/Coupon';
import { EventRoutes } from './routes/Event';
import { FidelityRoutes } from './routes/FidelityCard';
import { LastMinuteRoutes } from './routes/LastMinute';
import { MessageRoutes } from './routes/Message';
import { NewsRoutes } from './routes/News';
import { ProductOrderRoutes } from './routes/ProductOrders';
import { ProductRoutes } from './routes/Products';
import { ProductsInfoRoutes } from './routes/ProductsInfo';
import { ReviewsRoutes } from './routes/Reviews';
import { SalesPointsRoutes } from './routes/SalesPoints';
import { SettingsRoutes } from './routes/Settings';
import { SurveyRoutes } from './routes/Survey';
import { UserPointsRoutes } from './routes/UserPoints';
import { ContactRoutes } from './routes/Contact';
import { FlyerRoutes } from './routes/Flyer';
import { GraphRoutes } from './routes/Graph';
import { AccountRoutes } from './routes/Account';
import { PrivacyRoutes } from './routes/Privacy';
import { HelpRoutes } from './routes/Help';
import { RefreshRoutes } from './routes/Refresh';
import { HomeRoutes } from './routes/Home';
import { NoticeRoutes } from './routes/Notice';
import { DeliveryRoutes } from './routes/Delivery';
import { TicketsRoutes } from './routes/Tickets';
import { ManageUsers } from './routes/ManageUsers';
import { BannersRoutes } from './routes/Banners';
import { CategoriesRoutes } from './routes/Categories';
import { VideoTourRoutes } from './routes/VideoTour';

const Login = lazy(() => import('../pages/login/LoginPage'));
const ForgotPassword = lazy(() => import('../pages/forgot_password/ForgotPassword'));
const NewPassword = lazy(() => import('../pages/forgot_password/NewPassword'));
const NotFound = lazy(() => import('../pages/wrong_route/PageNotFound'));


const Routes = [
  ...HomeRoutes,
  ...FidelityRoutes,
  ...ProductRoutes,
  ...ProductsInfoRoutes,
  ...ProductOrderRoutes,
  ...LastMinuteRoutes,
  ...CouponRoutes,
  ...UserPointsRoutes,
  ...MessageRoutes,
  ...FlyerRoutes,
  ...SalesPointsRoutes,
  ...DeliveryRoutes,
  ...TicketsRoutes,
  ...NewsRoutes,
  ...EventRoutes,
  ...ReviewsRoutes,
  ...SurveyRoutes,
  ...NoticeRoutes,
  ...ContactRoutes,
  ...PrivacyRoutes,
  ...SettingsRoutes,
  ...AccountRoutes,
  ...HelpRoutes,
  ...GraphRoutes,
  ...RefreshRoutes,
  ...ManageUsers,
  ...BannersRoutes,
  ...CategoriesRoutes,
  ...VideoTourRoutes

 
]


class RoutersList extends Component {
    render() {
        return (
            <div>
                <Switch>

                    <Suspense fallback={<div className="loader"></div>}>
                        <Route exact path="/" component={Login} />
                        <Route exact path="/forgot_password" component={ForgotPassword} />
                        <Route exact path="/forgot_password/change_password" component={NewPassword} />
                        {Routes.map((route, index) => {
                            return (
                                <ProtectedRoute key={index} {...route} />
                            )
                        })}

                    </Suspense>
                    <ProtectedRoute exact component={NotFound} />
                </Switch>

            </div>
        );
    }
}

export default RoutersList; 